function CreateOrgRepo() {
    const createRepo = e => {
        e.preventDefault();

        const orgId = document.getElementById("orgId").value;

        fetch(`https://open.omnifi.io/admin/${orgId}/customize`, {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({ src_repo: "omnifi/easy" }),
            headers: {
                'X-Authentication-No-Redirect': true,
                'Content-Type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then(() => {
                alert(`Repo created for ${orgId}!`);
            })
            .catch((error) => {
                alert(`Error creating repo for ${orgId}: ${error}`);
            });
    }

    return (
        <form onSubmit={createRepo}>
            <h1>Create New Organization Repo</h1>
            <label htmlFor="orgId">Organization ID:</label>
            <br />
            <input type="text" id="orgId" name="orgId" />
            <br />
            <br />
            <button>Create Repo</button>
        </form>
    );
}

export default CreateOrgRepo;