import { useEffect, useState } from 'react';

import dayjs from 'dayjs';

import 'sortable-tablesort/sortable.min.css'
import 'sortable-tablesort/sortable.min.js'
import './HeartBeat.css';

function HeartBeat() {
    const [data, setData] = useState(null);

    const secPassedToFromatted = s => {
        const days = s / (24 * 60 * 60);
        const hours = (days % 1) * 24;
        const minutes = (hours % 1) * 60;
        const secs = (minutes % 1) * 60;

        return `${Math.floor(days)}d, ${Math.floor(hours)}h, ${Math.floor(minutes)}m, ${Math.floor(secs)}s`;
    }

    const sortData = data => {
        const { current_time, service_start_time, system_boot_time, last_heartbeats_by_strip } = data;

        const lastUpdateTime = dayjs.unix(current_time);
        const serviceUptime = dayjs.unix(service_start_time);
        const systemUptime = dayjs.unix(system_boot_time);

        let newData = {
            lastUpdateTime: {
                formatted: lastUpdateTime.format('MM/DD/YYYY HH:mm:ss'),
                s: current_time
            },
            serviceUptime: {
                formatted: serviceUptime.format('MM/DD/YYYY HH:mm:ss'),
                s: service_start_time
            },
            systemUptime: {
                formatted: systemUptime.format('MM/DD/YYYY HH:mm:ss'),
                s: system_boot_time
            },
            last_heartbeats_by_strip: {},
        };

        for (const key in last_heartbeats_by_strip) {
            const item = last_heartbeats_by_strip[key];
            const date = dayjs.unix(item.last_heartbeat_timestamp);
            const sDiff = lastUpdateTime.diff(date, 's');

            const formattedLastSeen = secPassedToFromatted(sDiff);
            const uptime_at_heartbeat = item.uptime_at_heartbeat > 0 ? secPassedToFromatted(item.uptime_at_heartbeat) : '';

            newData.last_heartbeats_by_strip[key] = {
                lastSeen: {
                    formatted: formattedLastSeen,
                    ms: sDiff,
                },
                uptime: {
                    formatted: uptime_at_heartbeat,
                    ms: item.uptime_at_heartbeat < 0 ? 'a' : item.uptime_at_heartbeat,
                }
            };
        }

        return newData;
    }

    useEffect(() => {
        async function fetchData() {
            const heartbeats = await fetch('https://cloud.omnifi.io/discovery/liveness-report', {
                headers: {
                    'X-Authentication-No-Redirect': true,
                    'Content-Type': 'application/json',
                },
                credentials: 'include'
            });

            if (heartbeats.status === 401 || heartbeats.status === 404) {
                window.location = `https://dev.omnifi.io/create/login?redirect=${window.location.href}`;
            } else {
                const json = await heartbeats.json();

                setData(sortData(json));
            }
        }

        fetchData();
    }, []);

    useEffect(() => {
        if (data) {
            document.getElementById('lastSeen').click();
        }
    }, [data]);

    return (
        <div className="HeartBeat">
            {data && (
                <>
                    <table className="sortable asc">
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Last Update Time</td>
                                <td data-sort={data.lastUpdateTime.s}>{data.lastUpdateTime.formatted}</td>
                            </tr>
                            <tr>
                                <td>Service Uptime</td>
                                <td data-sort={data.serviceUptime.s}>{data.serviceUptime.formatted}</td>
                            </tr>
                            <tr>
                                <td>System Uptime</td>
                                <td data-sort={data.systemUptime.s}>{data.systemUptime.formatted}</td>
                            </tr>
                        </tbody>
                    </table>
                    <table className="sortable asc">
                        <thead>
                            <tr>
                                <th>Strip Name</th>
                                <th id="lastSeen">Last Seen</th>
                                <th>Uptime</th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(data?.last_heartbeats_by_strip).map((key) => {
                                const item = data.last_heartbeats_by_strip[key];
                                const { lastSeen, uptime } = item;

                                return (
                                    <tr key={key}>
                                        <td>{key}</td>
                                        <td data-sort={lastSeen.ms}>{lastSeen.formatted}</td>
                                        <td data-sort={uptime.ms}>{uptime.formatted}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </>
            )}
        </div>
    );
}

export default HeartBeat;
