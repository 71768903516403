const links = [
  {
    name: 'Strip Heartbeat',
    href: 'heartbeat',
  },
  {
    name: 'Create New Org Repo',
    href: 'create-org-repo',
  },
];

function App() {
  return (
    <div>
      <nav>
        <ul>
          {links.map((link, index) => (
            <li key={index}>
              <a href={`/${link.href}`}>{link.name}</a>
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
}

export default App;
